import styled, { css } from 'styled-components';
import { Button, Column, Row, Text, injectModStyles } from 'src/components';
import { Modal } from 'src/containers';
import { ModalChildContainer } from 'src/containers/Modal/styled';
import { font, theme } from 'src/utils';

export const InventoryModal = styled(Modal)`
  @media (max-width: 960px) {
    min-width: 100vw;
  }
  ${ModalChildContainer} {
    max-width: 60vw;
    ${(props) => props.modStyles && injectModStyles(props.modStyles)}
    @media (max-width: 960px) {
      padding: 0;
      min-width: 100%;
    }
  }
  h6 {
    @media (max-width: 960px) {
      font-size: ${font.sizes.base};
    }
  }
`;

export const SectionHeader = styled(Row)`
  gap: 0.5rem;
  align-items: center;
  background-color: ${theme.primary[100]};
  border-radius: 0.5rem;
  padding: 1rem;
  color: ${theme.primary[500]};
  font-weight: ${font.weights.semibold};
  @media (max-width: 960px) {
    font-size: ${font.sizes.sm};
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  button {
    padding: 0;
  }
`;

export const AccordionButton = styled(Button)`
  color: ${({ color }) => color || theme.grey[700]};
  @media (max-width: 600px) {
    gap: 0.125rem;
    font-size: ${font.sizes.xxs};
    font-weight: ${font.weights.semibold};
    & > svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
  & > svg {
    stroke: none;
    & > path {
      fill: ${({ color }) => color || theme.grey[700]};
      stroke: none;
    }
  }
`;

export const FlexContainer = styled(Row)`
  flex: 1;
  & > div {
    flex: 1;
  }
`;

export const TableContent = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
  overflow: auto;
  table {
    width: 100%;
    margin: 0 0.125rem;
    border-collapse: collapse;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    overflow: hidden;
    thead > tr {
      position: sticky;
      top: 0;
      white-space: nowrap;
      background-color: ${theme.grey[100]};
      z-index: 5;
    }
    & th,
    td {
      text-align: start;
    }

    & th {
      padding: 1rem;
      color: ${theme.grey[700]};
      font-size: ${font.sizes.sm};
      font-weight: ${font.weights.semibold};
      @media (max-width: 960px) {
        font-size: ${font.sizes.xxs};
        padding: 1rem 0.5rem;
      }
    }

    & td {
      padding: 1rem;
      // font-weight: ${font.weights.normal};
      // color: ${theme.black[100]};
      white-space: nowrap;
      @media (max-width: 960px) {
        font-size: ${font.sizes.xs};
        padding: 1rem;
      }
    }
  }
`;

export const TR = styled.tr<{
  colored?: boolean;
}>`
  & td {
    text-transform: capitalize;
  }
  & td,
  th {
    text-align: left;
  }
  ${({ colored }) => {
    if (colored)
      return css`
        background-color: ${theme.grey[100]};
      `;
    return '';
  }}
`;

export const LogsScrollContainer = styled(Column)`
  height: calc(100vh - 35rem);
  padding-bottom: 2.5rem;
  padding-left: 0.5rem;
  padding-right: 0.25rem;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 960px) {
    max-height: calc(100vh - 32rem);
  }
`;

export const ItemCard = styled(Row)`
  border-radius: 10px;
  padding: 15px;
  border: 0.5px solid #f4f4f4;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
`;

export const ItemIndicator = styled.div`
  height: 0.94rem;
  width: 0.94rem;
  display: block;
  border-radius: 50%;
  border: 0.3125rem solid ${(props) => props.color};
  position: absolute;
  left: -2rem;
  top: 1.7rem;
  background: #fff;
  box-shadow: 0px 0px 5px 1px rgba(135, 107, 101, 0.19);
`;

export const VerticalLineSharp = styled.div`
  position: absolute;
  height: 100%;
  left: -1.56rem;
  top: 1.7rem;
  border-left: 1px dashed ${(props) => props.color};
`;

export const StyledLink = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;

export const LogTag = styled(Text)<{
  marginLeft?: number;
  marginRight?: number;
}>`
  display: flex;
  margin-bottom: 0;
  margin-left: ${(props) => `${props.marginLeft || 0}rem`};
  margin-right: ${(props) => `${props.marginRight || 0}rem`};
  font-weight: 600;
  font-size: 0.875rem;
  color: ${theme.grey[700]};
  & svg {
    margin-right: 0.31rem;
    min-width: 1rem;
    min-height: 1rem;
  }
`;

export const WrappedText = styled.p`
  max-width: 250px;
  white-space: normal;
  overflow: hidden;
`;
