import { gql } from '@apollo/client';
import { invoiceFieldFragment } from '../Fragments/invoice';

export const ADD_INVOICE = gql`
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      message
      success
    }
  }
`;

export const DELETE_INVOICE = gql`
  mutation DeleteInvoice($invoiceId: String!) {
    deleteInvoice(invoiceId: $invoiceId) {
      message
      success
    }
  }
`;

export const ADD_INVOICE_ITEM = gql`
  mutation AddInvoiceItem(
    $input: InvoiceItemInput!
    $invoiceId: String
    $patientId: String!
  ) {
    addInvoiceItem(
      input: $input
      invoiceId: $invoiceId
      patientId: $patientId
    ) {
      message
      success
      invoice {
        _id
        invoiceId
        createdAt
        patient {
          user {
            firstName
            lastName
            fullName
            emails
          }
        }
        hmo {
          _id
          name
          hmoId
        }
      }
      item {
        _id
      }
    }
  }
`;

export const UPDATE_INVOICE_ITEM = gql`
  mutation UpdateInvoiceItem(
    $invoiceItemId: String!
    $input: InvoiceItemInput
  ) {
    updateInvoiceItem(invoiceItemId: $invoiceItemId, input: $input) {
      message
      success
      item {
        units
      }
    }
  }
`;

export const DELETE_INVOICE_ITEM = gql`
  mutation DeleteInvoiceItem($invoiceItemId: String!) {
    deleteInvoiceItem(invoiceItemId: $invoiceItemId) {
      message
      success
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UpdateInvoice($input: UpdateInvoiceInput!, $invoiceId: String!) {
    updateInvoice(input: $input, invoiceId: $invoiceId) {
      invoice {
        ...InvoiceFields
      }
      message
      success
    }
  }
  ${invoiceFieldFragment}
`;

export const MAKE_INVOICE_PAYMENT = gql`
  mutation MakeInvoicePayment(
    $input: MakeInvoicePaymentInput!
    $invoiceId: String!
  ) {
    makeInvoicePayment(input: $input, invoiceId: $invoiceId) {
      success
      message
      invoice {
        ...InvoiceFields
      }
    }
  }
  ${invoiceFieldFragment}
`;

export const ADD_PRODUCT_INVOICE_ITEM = gql`
  mutation AddProductInvoiceItem(
    $input: ProudctInvoiceItemInput!
    $invoiceId: String
  ) {
    addProductInvoiceItem(input: $input, invoiceId: $invoiceId) {
      message
      success
      invoice {
        _id
        invoiceId
        createdAt
        hmo {
          _id
          name
          hmoId
        }
      }
      item {
        _id
      }
    }
  }
`;

export const ADD_TO_PATIENT_INVOICE = gql`
  mutation AddInvoiceToPatientInvoice(
    $invoiceId: String!
    $patientId: String!
  ) {
    addInvoiceToPatientInvoice(invoiceId: $invoiceId, patientId: $patientId) {
      success
      message
    }
  }
`;

export const MARK_INVOICE_AS_PAID = gql`
  mutation MarkInvoicePaid($input: MarkInvoicePaidInput!, $invoiceId: String!) {
    markInvoicePaid(input: $input, invoiceId: $invoiceId) {
      message
      success
      invoice {
        ...InvoiceFields
      }
    }
  }
  ${invoiceFieldFragment}
`;

export const RETURN_INVOICE_ITEM = gql`
  mutation ReturnInvoiceItem($input: ReturnInvoiceItemInput!) {
    returnInvoiceItem(input: $input) {
      message
      success
    }
  }
`;
