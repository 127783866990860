import { gql } from '@apollo/client';
import { batchFieldsFragment, productFragment } from '../Fragments/inventory';

export const ADD_PRODUCT = gql`
  mutation AddProduct($input: AddProductInput!) {
    addProduct(input: $input) {
      message
      success
      products {
        ...productFields
      }
    }
  }
  ${productFragment}
`;

export const ADD_BULK_PRODUCTS = gql`
  mutation InventoryBulkUpload($file: Upload!) {
    inventoryBulkUpload(file: $file) {
      message
      success
    }
  }
`;

export const EDIT_PRODUCT = gql`
  mutation EditProduct($input: UpdateProductInput!, $productId: ID!) {
    editProduct(input: $input, productId: $productId) {
      message
      success
      product {
        ...productFields
      }
    }
  }
  ${productFragment}
`;

export const ADD_PRODUCT_BATCH = gql`
  mutation AddProductBatch($input: BatchInput!, $productId: ID!) {
    addProductBatch(input: $input, productId: $productId) {
      message
      success
      batch {
        ...batchFields
      }
    }
  }
  ${batchFieldsFragment}
`;

export const EDIT_PRODUCT_BATCH = gql`
  mutation EditProductBatch($batchId: ID!, $input: UpdateProductBatchInput!) {
    editProductBatch(batchId: $batchId, input: $input) {
      message
      success
      batch {
        ...batchFields
      }
    }
  }
  ${batchFieldsFragment}
`;

export const PUSH_TO_SHELF = gql`
  mutation PushToShelf($batchId: ID!, $input: PushToShelfInput) {
    pushToShelf(batchId: $batchId, input: $input) {
      message
      success
    }
  }
`;


export const BATCH_PUSH_TO_SHELF = gql`
  mutation PushProductToShelf($input: PushProductToShelfInput!) {
    pushProductToShelf(input: $input) {
      message
      success
    }
  }
`