import { Invoice } from 'src/types';

export const tableHeaders = [
  'Service Name',
  'Quantity',
  'Payment',
  'Cost Per Unit',
  'Cost Total',
];

export const previewHeaders = [
  'Service Name',
  'Payment',
  'Cost Per Unit',
  'Quantity',
  'Cost Total',
];

export const inputHeaders = [
  'Item',
  'Payment Mode',
  'Quantity',
  '',
  'Cost Per Unit',
  '',
];

export const itemValidation = {
  description: 'required',
  isHMOPayable: 'required',
  unitCost: 'required',
  units: 'required',
};

export const defaultState = {
  items: [
    {
      serviceCode: '',
      isHMOPayable: 'OOP',
      units: 0,
      unitCost: 0,
      formattedCost: '',
      description: '',
      currency: '',
    },
  ],
  notes: '',
  paymentMethod: 'CASH',
};

export const data = [
  {
    description: 'Covide Test Kit',
    units: 1,
    isHMOPayable: 'HMO',
    unitCost: 74000,
  },
  {
    description: 'Covide Test Kit',
    units: 1,
    isHMOPayable: 'HMO',
    unitCost: 74000,
  },
  {
    description: 'Covide Test Kit',
    units: 1,
    isHMOPayable: 'HMO',
    unitCost: 74000,
  },
];

export const paymentOptions = [
  {
    label: 'Cash',
    value: 'CASH',
  },
  {
    label: 'Pos',
    value: 'POS',
  },
  {
    label: 'Transfer',
    value: 'TRANSFER',
  },
];

export const servicePaymentMethod = [
  {
    label: 'Out Of Pocket',
    value: 'OOP',
  },
  {
    label: 'HMO Payable',
    value: 'HMO',
  },
];

export const summaries = [
  {
    summary: 'Covide Test Kit',
    col2: '',
    col3: '',
    total: '₦ 74,000',
  },
  {
    summary: 'Covide Test Kit',
    col2: '',
    col3: '',
    total: '₦ 74,000',
  },
  {
    summary: 'Covide Test Kit',
    col2: '',
    col3: '',
    total: '₦ 74,000',
  },
];

export const calculations = [
  {
    summary: 'Total HMO Payment',
    col2: '',
    col3: '',
    total: '₦ 374,000',
  },
  {
    summary: 'Total Out-of-Pocket',
    col2: '',
    col3: '',
    total: '₦ 4,000',
  },
  {
    summary: 'Sub-Total',
    col2: '',
    col3: '',
    total: '₦ 59,000',
  },
];

export const getTotals = (invoice: Invoice) => {
  if (invoice.cashAmount || invoice.posAmount || invoice.transferAmount) {
    return [
      {
        summary: 'Sub-Total',
        col2: '',
        col3: '',
        total: invoice.oopAmount || 0,
      },
      {
        summary: 'Total HMO Payment',
        col2: '',
        col3: '',
        total: invoice.hmoAmount,
      },
      {
        summary: 'Total Cash Amount',
        col2: '',
        col3: '',
        total: invoice.cashAmount,
      },
      {
        summary: 'Total POS Amount',
        col2: '',
        col3: '',
        total: invoice.posAmount,
      },
      {
        summary: 'Total Transfer Amount',
        col2: '',
        col3: '',
        total: invoice.transferAmount,
      },
      {
        summary: 'Outstanding Payment',
        col2: '',
        col3: '',
        total: (invoice.oopAmount || 0) - (invoice.oopAmountPaid || 0),
      },
    ];
  }
  return [
    {
      summary: 'Total HMO Payment',
      col2: '',
      col3: '',
      total: invoice.hmoAmount,
    },
    {
      summary: 'Total Out-of-Pocket',
      col2: '',
      col3: '',
      total: invoice.oopAmount,
    },
    {
      summary: 'Sub-Total',
      col2: '',
      col3: '',
      total: (invoice.hmoAmount || 0) + (invoice.oopAmount || 0),
    },
  ];
};

export const previewSummary = [
  {
    col1: '',
    summary: 'VAT',
    col3: '',
    total: '₦ 24,000',
  },
  {
    col1: '',
    summary: 'Total HMO Payment',
    col3: '',
    total: '₦ 374,000',
  },
  {
    col1: '',
    summary: 'Total Out-of-Pocket',
    col3: '',
    total: '₦ 4,000',
  },
  {
    col1: '',
    summary: 'Sub-Total',
    col3: '',
    total: '₦ 59,000',
  },
];
